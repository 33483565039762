@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'D Din';
  src: local('D Din'), url(./assets/fonts/D-DIN.otf) format('opentype');
}
@font-face {
  font-family: 'D Din';
  src: local('D Din'), url(./assets/fonts/D-DIN-Bold.otf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'D Din';
  src: local('D Din'), url(./assets/fonts/D-DIN-Italic.otf) format('opentype');
  font-style: italic;
}
@font-face {
  font-family: 'D Din Expand';
  src: local('D Din'), url(./assets/fonts/D-DINExp.otf) format('opentype');
}
@font-face {
  font-family: 'D Din Expand';
  src: local('D Din'), url(./assets/fonts/D-DINExp-Bold.otf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'D Din Expand';
  src: local('D Din'), url(./assets/fonts/D-DINExp-Italic.otf) format('opentype');
  font-style: italic;
}
@font-face {
  font-family: 'D Din Condensed';
  src: local('D Din'), url(./assets/fonts/D-DINCondensed.otf) format('opentype');
}
@font-face {
  font-family: 'D Din Condensed';
  src: local('D Din'), url(./assets/fonts/D-DINCondensed-Bold.otf) format('opentype');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'Tenor Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
